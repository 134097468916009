import {put,takeEvery,all,call} from 'redux-saga/effects';
import axios from 'axios';
import { BACKEND_URL } from "../../config"; 


function* getCollection()
{
	yield put({type:'SET_POSTING'});	
	const collection=yield axios.get(BACKEND_URL+'/flowone/getcollections');
	yield put({type:'GET_ALL_COLLECTION_LIST_SUCCESS',responseData:collection});	
}
function* profile({data})
{
	yield put({type:'SET_POSTING'});	
	const profiles=yield axios.post(BACKEND_URL+'/flowone/profile',data);
	yield put({type:'POST_ALL_PROFILE__SUCCESS',responseData:profiles});
		
}

function* actionWatcher()
{

	yield takeEvery("GET_ALL_COLLECTION_LIST",getCollection);
	yield takeEvery("POST_ALL_PROFILE_REQUEST",profile);



}
export default function* rootSaga()
{
	yield all([actionWatcher()]);
}