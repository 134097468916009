import React, { Component, Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch, Link
  } from 'react-router-dom';
  



const ViewInnerpages = React.lazy(() =>
import(/* webpackChunkName: "views" */ './views/innerpages')
);



class App extends Component {

   async componentDidMount(event) {
      const favicon = this.getFaviconEl();

      } 
   getFaviconEl = () => {      return document.getElementById("favicon");    }

  render() {
  return (
     <React.Fragment>
     <Suspense fallback={<div className="loading" />}> 
     <Router  history={this.props.history}>
        <Switch>
           <Route
              path="/"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
             <Route
            path="/drops"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
           <Route
            path="/mktplacecollectionlist"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
          <Route
            path="/nftmint/:id"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
          <Route
            path="/marketplacebranddetails/:id"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
         <Route
            path="/transactionapproval"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
          <Route
            path="/transactionblockchain"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
          <Route
            path="/transactionfailed"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
           <Route
            path="/transactionfailedblockchain"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
          <Route
            path="/finishpayment"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
         <Route
            path="/statsranking"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
          <Route
            path="/profile"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
           <Route
            path="/marketdeleteitem/:id"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
            <Route
            path="/marketdeletetransaction/:id"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
            <Route
            path="/sellpage/:id"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
           <Route
            path="/mktnftdetails/:id"
            exact
            render={props =>
         <ViewInnerpages {...props} />
         }
         />
           <Route
              path="/nftaftersell/:id"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
           <Route
              path="/sellingnftdetails/:id"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
           <Route
              path="/wallet"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
            <Route
              path="/profile1"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
            <Route
              path="/about"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
            <Route
              path="/joinus"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
           <Route
              path="/privacy"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
           <Route
              path="/terms"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           
           />
            <Route
              path="/transaction"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           
           />
            <Route
              path="/search"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           
           />
            <Route
              path="/loader"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           
           
           />
           <Route
              path="/upcoming/:id"
              exact
              render={props =>
           <ViewInnerpages {...props} />
           }
           />
          
           
        </Switch>
     </Router>
     </Suspense>
  </React.Fragment>
  );
  }
  }
  
  export default App;











