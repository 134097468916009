import {createStore,applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMidddleware from 'redux-saga';
import rootSaga from './sagas';
import rootReducer from './reducers';

const sagaMiddleware=createSagaMidddleware();
const store=createStore(

		rootReducer,
		{},

		composeWithDevTools(applyMiddleware(sagaMiddleware))

	) ;
sagaMiddleware.run(rootSaga);
export default store;