const loading=false;
const reducer=(state={loading},action)=>{

		switch(action.type)
		{

			case 'SET_POSTING':

					return{...state,loading:true}	
			case 'GET_ALL_COLLECTION_LIST_SUCCESS':

					return{...state,loading:false,collection:action.responseData}
			case 'SET_POSTING':

						return{...state,loading:true}	
			case 'POST_ALL_PROFILE__SUCCESS':
	
						return{...state,loading:false,profiles:action.responseData}

			default :

					return state;

		}


}
export default reducer;